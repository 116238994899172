.footer-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: auto;
    background-color: black;
}

.footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    min-height: 72px;
    
}

.footer-para-div{
    display:flex;
    margin-right: 10px;
    font-family: 'mariupol';
    color: white;
}
.footer-links-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left:50px;
}

.footer-links-btn p{
    margin-right: 40px;

}

.footer-para-div a{
    text-decoration: none;
    color: inherit;
}
.footer-logo{
    margin-left: 5%;
    margin-right: auto;
}
.msm-ftr{
    min-width: 153px;
}
.ftr-insta-btn{
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}
.ftr-linkdin-btn{
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}
@media (max-width: 550px) {
    .footer-para-div{
        display: none;
    }
    .footer-logo{
        margin-right: 30%;
    }
    .ftr-insta-btn{
        display: inline;
        
    }
    .ftr-linkdin-btn{
        display: inline;
}
    .small-footer{
        margin-right: -10px;
    }
    .footer-container{
        margin-left: -20px;
    }
}