.home-page{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh;
}
.component-spacing-1{
    height: 70px;
}
.component-spacing-2{
    height: 110px;
}
.component-spacing-3{
    height: 100px;
}
.component-spacing-4{
    height: 80px;
}
.component-spacing-5{
    height: 50px;
}
.component-spacing-6{
    height: 90px;
}
.component-spacing-7{
    height: 90px;
}
.component-spacing-8{
    height: 20px;
}
.component-spacing-9{
    height: 50px;
}

