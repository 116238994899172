.Museums-list-cards-holder {
  display:flex;
  margin-bottom: 15px;
  justify-content:center;
  flex-wrap: wrap;
  
}
.museum-card-spacing {
 margin: 20px;
}
.museum-list-wrapper {
  display: flex;
  justify-content: center;
}
.museum-list-container {

  margin-top: 50px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-left: 20px;
}
.musuem-list-headlines {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  
  
}
.musuem-list-headlines h1 {
  font-family: "glock";
  font-size: 40px;
  margin-bottom: 0px;
}
.musuem-list-headlines p {
  font-family: "aktiv";
  font-size: 20px;
  max-width: 936px;
  line-height: 1.4;
  margin-top: 10px;
  text-align: center;
}
.museum-list-buttons {
  display: flex;
  justify-content: center;
  margin-right:50px;
}

.cardRow-container {
  height: auto;
  
}
.index-btns {
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  font-family: "OpenSans";
}
.left-msm-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}
.right-msm-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}
.left-msm-btn.final {
  opacity: 30%;
}
.right-msm-btn.final {
  opacity: 30%;
}
.index-btns.active {
  border: 1px solid black;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 5px;
  font-family: "OpenSans-Bold";
}
@media (min-width: 1282px) and (max-width:1322px){
  .museum-card-spacing {
 margin: 10px;
}
}
@media (min-width:957px) and (max-width:996px){
  .museum-card-spacing {
 margin: 10px;
}
}
@media (min-width:631px) and (max-width:671px){
  .museum-card-spacing {
 margin: 10px;
}
}
@media(min-width: 599px) and (max-width: 631px){
  .museum-card-spacing {
 margin: 2px;
}
}
@media (max-width: 475px) {
  .musuem-list-headlines h1 {

  font-size: 25px;
  margin-top: -15px;
  text-align: left;
}
.musuem-list-headlines p {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 15px;
  text-align: left;
}
.musuem-list-headlines {
  margin-right: 5%;
}
.cardRow-container {
  margin-left:-20px;
}
}
@media (min-width: 475px) and (max-width: 640px) {
  .musuem-list-headlines h1 {
    text-align: left;
  }
  .musuem-list-headlines p {

  text-align: left;
}
}
@media (min-width: 640px) and (max-width: 768px) {
  .musuem-list-headlines h1 {
    text-align: center;
  }
  .musuem-list-headlines p {

  text-align: center;
}
}
@media (min-width:768px) and (max-width:833px) {
  .musuem-list-headlines h1{
    text-align: center;
}
}