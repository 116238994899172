.musuems-container-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.museums-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.Museums-cards-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.Museums-headline{
    font-family: 'glock';
    font-size: 40px;
    margin-bottom: 40px;

}
.museums-btn-one{
    margin-right: 30px;
    border: none;
    background-color: transparent;
    font-family: 'Mariupol';
    width: 125px;
    height: 24px;
    font-weight: bold;
    text-decoration: underline;
}
.museum-btn-img{
    margin-bottom: -4px;
    margin-left: 7px;
}
.museums-btn-one:hover{
    cursor:pointer;
}
.museums-btn-two{
    width: 185px;
    height: 42px;
    border-radius: 30px;
    background-color: white;
    font-family: 'Mariupol';
    font-weight: bold;
    border: 3px solid black;
    font-size: 16px;
    margin-top: 20px;
}
.museums-btn-two:hover{
    background-color: lightgray;
    cursor: pointer;
}
.museum-card-spacing-main{
    margin: 20px;
}
.Museums-cards-holder > :last-child {
  margin-right: 40px;
}


@media (max-width: 475px) {
    .Museums-headline{
    text-align: center;
    font-size: 33px;
    padding-left: 5px;
    padding-right: 5px;

}
.Museums-cards-holder{
    margin-left: 11%;
}
.museums-btn-container{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}
.museums-btn-one{
    margin-right: -20px;
font-size: 16px ;
margin-top: 25px;
width: 150px;
}

}
@media (min-width: 475px) and (max-width: 611px){
   .Museums-headline{
    font-size: 38px;
    margin-bottom: 40px;
    text-align: center;

}
.Museums-cards-holder{
    margin-left: 7%;
}
}
@media (min-width: 611px) and (max-width:651px ){
    .Museums-headline{
    text-align: center;

}
.Museums-cards-holder > :last-child {
  margin-right: 0px;
}
.museum-card-spacing-main{
    flex-basis: .1%;
    margin-left: -16px;
}
}
@media (min-width: 651px) and (max-width: 897px){
    .Museums-headline{
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;

}
.Museums-cards-holder{
    margin-right: -40px;
}
}
@media (min-width: 977px) {
    .Museums-cards-holder{
    margin-right: -40px;
}
}
@media (min-width: 897px) and (max-width: 937px) {
    .Museums-cards-holder{
    margin-left: 25px;
}
}
