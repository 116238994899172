.testimony-detail-wrapper {
  display: flex;
  justify-content: center;
}
.testimony-detail-container {
  margin-top: 50px;
  max-width: 616px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.testimony-detail-imgs {
  height: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.testimony-detail-imgs img {
  border-radius: 10px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.detail-right-btn.end {
  opacity: 30%;
}
.detail-left-btn.end {
  opacity: 30%;
}
.testimony-detail-back {
  margin-left: -90%;
  margin-bottom: 20px;
}
.testimony-detail-back button {
  background-color: transparent;
  border: none;
  font-family: "Mariupol";
  font-size: 18px;
  cursor: pointer;
}
.testimony-detail-back img {
  margin-bottom: -6px;
}
.detail-right-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.detail-left-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.testimony-detail-img-toggle {
  display: flex;
  justify-content: center;
  font-family: "Mariupol";
}
.testimony-detail-text {
  font-family: "Mariupol";
  line-height: 1.6;
}
.testimony-detail-socials {
  font-family: "Mariupol";
  text-align: center;
  font-size: 8px;
  margin-top: 20px;
  margin-bottom: 70px;
}
.testimony-detail-socials button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 10px;
}
ul {
  content: "\2022";
}
.example-list {
  margin-left: -10px;
}
.detail-date {
  color: #414141;
  margin-bottom: -10px;
}

@media (max-width: 475px) {
  .testimony-detail-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 30px;
  }
  .testimony-detail-back {
    margin-left: -80%;
  }
}
@media (min-width: 475px) and (max-width: 640px) {
  .testimony-detail-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}
