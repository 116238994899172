.Bank-info-wrapper {
  display: flex;
  justify-content: center;
}
.Bank-info-container {
  max-width: 976px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 200px;
}
.Bank-info-headline {
  font-family: "glock";
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
}
.clover-QR {
  max-width: 776px;
  display: flex;
  border: 2px solid lightgray;
  padding: 30px 30px 30px 30px;
  border-radius: 20px;
  flex-wrap: wrap;
}

.PayPal-QR {
  margin-top: 50px;
  max-width: 776px;
  display: flex;
  border: 2px solid lightgray;
  padding: 30px 30px 30px 30px;
  border-radius: 20px;
  flex-wrap: wrap;
}
.clover-info {
  margin-top: 65px;
  margin-left: 60px;
  font-family: "montserrat";
}

.paypal-info {
  margin-left: 40px;
  margin-top: 30px;
  font-family: "montserrat";
}
.bank-clover {
  margin-bottom: -7px;
}

.paypal-one {
  margin-bottom: -7px;
}
.paypal-qr-img img{
  max-width: 186px;
}
.clover-qr-img img{
  margin-top: 10px;
  margin-left: 10px;
  max-width: 156px;
}
@media (max-width: 704px) {
  .QR-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .clover-QR {
    flex-direction: column-reverse;
  }

  .PayPal-QR {
    flex-direction: column-reverse;
    margin-bottom: 50px;
    margin-top: 0;
  }
  .clover-info {
    margin-top: 0;
    margin-left: 0;
    font-family: "montserrat";
  }
  .paypal-info {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: "montserrat";
  }
  .paypal-qr-img {
    margin-left: 3px;
  }
  .Bank-info-headline {
    
    font-size: 18px;
  }
}
@media (max-width: 475px) {
  .clover-QR {
    margin-left: 3%;
    margin-right: 5%;
    text-align: center;
  }

  .PayPal-QR {
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
  }
  .Bank-info-headline {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 13px;
  }
}
@media (max-width: 355px) {
  .Bank-info-headline {
    
    font-size: 11px;
  }
  
}
