.slider-container-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.slider-container{
    width: 1440px;
    height: 230px;
    display: flex;
    flex-direction: row;

    overflow-x:scroll;
    margin-top: 40px;
    margin-bottom: 40px;
}
.slider-imgs{
    display: flex;
}
.slider-imgs-container{
    width: 160px;
    height: 160px;
    margin-right: 40px;
}
.slider-imgs-container img{
    width: 100%;
    height:auto;
}
.slider-container::-webkit-scrollbar {
    width:0;
}