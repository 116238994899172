.process-container-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.process-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.process-steps-container{
    display: flex;
    margin-right: -55px;
    justify-content: center;
    flex-wrap: wrap;
}
.image-text-container{
    text-align: center;
    position: relative;
    margin-right: 60px;
}
.steps{
    width: 296px;
    font-family: 'Mariupol';
    font-size: 21px;
    font-weight: bold;
    line-height: 1.4;
}
.overlay-text{
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    font-family: 'glock';
    font-size: 25px;
}
.process-headline{
    font-family: 'glock';
    font-size: 16px;
    margin-bottom: 10px;
}
@media (min-width: 657px) and (max-width: 1012px) {
.process-steps-container{
    margin-right: -45px;
    
}
}