.navbar-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
}


.navbar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 10px;
    max-width: 1250px;
    width: 100%;
   
    
    
}
.navbar-logo-container{
    margin-right: auto;
}
.navbar-logo{
    margin-left: 30%;
}


.navbar-links-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'aktiv';
    font-size: 16px;
    
}
.navbar-links-btn a{
    text-decoration: none;
    color: inherit;
    
}
.msm-lst-link{
    min-width: 113px;
    
}
.bank-info-lnk{
    min-width: 119px;
}

.navbar-links-btn p{
    margin-right: 40px;
}

.navbar-btn{
    background-color: #000000;
    color: white;
    padding: 10px;
    line-height: 5px;
    border-radius: 20px;
    min-width: 144px;
    height: 32px;
    font-family: 'glockreg';
    border: 0px;
    font-size: 10px ;
}
.navbar-btn:hover{
    background-color: darkgray;
    cursor: pointer;
}

.navbar-contact-btn{
    border: none;
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    
}
.navbar-contact-btn:hover{
    cursor: pointer;
}

.menu{
    display: none;
    top: 12px;
    right: 8px;
    flex-direction: column;
    justify-content: space-between;
    width: 36px;
    height: 32px ;
    margin-right: 2%;
    margin-bottom: 3%;
}
.menu span{
    height: 6.4px;
    width: 100%;
    background-color: black;
    border-radius: 3.2px;

}
.open-menu{
    font-family: 'glock';
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 12px;
}
.open-menu a{
    color: inherit;
}

.menu-btn-spacing{
    height: 0px;
}
.msm-lst-link{
    margin-bottom:20px;
}
.test-page-link{
    margin-bottom: 20px;
}
@media (max-width: 921px) {
    .menu{
        display: flex;
    }
    .navbar-links-btn{
    display: none;
}

    
}
@media (min-width: 921px){
    .open-menu {
        display: none;
    }
}

