.image-preview-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    
    
}

.image-preview-container{
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    max-height: 500px;
    max-width: 700px;
    display: flex;
    align-items: center;
    margin-top: 300px;
    margin-left: 36px;

}
.preview-close-btn{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    z-index: -1;
    
}
.preview-images{
    width: 80%;
    height: 100%;
    border-radius: 25px;

}
.previous-img-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 2;
    
    
}
.next-img-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 2;
    
}

.next-img-btn img{
    transform: scaleX(-1);
    width: 120%;
    background-color: transparent;
    height: 120%;
   
}
.previous-img-btn img{
    width: 120%;
    height: 120%;
    background-color: transparent;
    margin-left: -10px;
}
.previous-img-btn.final:hover{
    opacity: 10%;
}
.next-img-btn.final:hover{
    opacity: 10%;
}
@media (max-width: 475px) {
.preview-images{
    width: 80%;
    height: 55%;
    border-radius: 25px;

}
.image-preview-container{
    margin-left: 2%;
}
}
@media (min-width:475px) and (max-width: 640px) {
.preview-images{
    width: 80%;
    height: 55%;
    border-radius: 25px;

}
.image-preview-container{
    margin-left: 2%;
}
}
@media (min-width: 640px) and (max-width: 768px) {
    .preview-images{
    width: 80%;
    height: 80%;
    border-radius: 25px;

}

}
