.Manifesto-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Manifesto-container {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    
}

.section-one{
    display: flex;
    margin-top: 70px;
    margin-bottom: 30px;
}
.Manifesto-headlines{
    max-width: 957px;
}
.Manifesto-container h1{
    font-family: 'glock';
}
.Manifesto-container p{
    font-family: 'aktiv';
}

.section-two{
    max-width: 1000px;
    margin-bottom: 50px;

}
.section-three{
    max-width: 1000px;
    margin-bottom: 100px;
  
}
.section-one-headline{
    font-size: 52px;
    line-height: 1.3;
}
.section-one p{
    max-width: 793px;
    font-size: 24px;
}
.section-one img{
    margin-top: 120px;
    margin-left: 140px;
}
.section-two h1{
    font-size: 40px;
}
.section-two p{
    font-size: 18px;
    max-width: 864px;
}
.section-three h1{
    font-size: 40px;
}
.section-three p{
    font-size: 18px;
    max-width: 856px;
}
.section-two button{
    background-color: #000000;
    color: white;
    padding: 10px;
    line-height: 5px;
    border-radius: 20px;
    min-width: 186px;
    height: 40px;
    font-family: 'glockreg';
    border: 0px;
    font-size: 13px;
    margin-top: 20px;
    cursor: pointer;
}
.section-three button{
    background-color: #000000;
    color: white;
    padding: 10px;
    line-height: 5px;
    border-radius: 20px;
    min-width: 158px;
    height: 40px;
    font-family: 'glockreg';
    border: 0px;
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
}
@media (min-width:320px)  {
    .Manifesto-container {
    
    width: 100%;
    margin-right: 2%;
    
}
}
@media (max-width: 777px) {
    .section-one img{
    display: none;
}
}
@media (min-width: 1280px) {
    .Manifesto-container{
        margin-left: 5%;
    }
}
@media (min-width: 1345px) {
    .Manifesto-container{
        margin-left: 7%;
    }
}
@media (min-width: 1430px) {
    .Manifesto-container{
        margin-left: 9%;
    }
}
@media (min-width: 1535px) and (max-width: 1720px) {
    .Manifesto-container{
        margin-left: 12%;
    }
}