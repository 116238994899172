.header-border-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width:1440px;
    height: auto;
    overflow: hidden;
}
.header-headlines-container{
    max-width: 596px; /*changed*/
    margin-left: 92px;

}
.header-headlines-container h1{
    font-family: 'glock';
    font-size: 53px;
}
.header-headlines-container p{
    font-family: 'aktiv';
    font-size: 24.4px;
    margin-top: -10px;
    margin-bottom: 25px;
    line-height: 1.4;
    
    
}
.header-imgs-container{
    margin-top: 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 596px;
    height: 401px;
    margin-right: 120px;
    position: relative;
}
.header-imgs-container img:first-child{
    width: 53%;
    height: 100%;
    object-fit: contain;
    margin-left: 300px;
}
.header-imgs-container img:last-child{
    width: 56%;
    height: 109%;
    object-fit: contain;
    margin-left: 300px;
}
.header-btn{
    background-color: #000000;
    color: white;
    padding: 10px;
    line-height: 6px;
    border-radius: 40px;
    width: 192px;
    height: 48px;
    font-family: 'glockreg';
    font-size: 13px;
    border: 0px;
    cursor: pointer;
    
}
.header-btn:hover{
    background-color: darkgray;
}

.tilt-img,.str-img{
    position: absolute;
}
.tilt-img{
    top: 9;
    left: -185px;
    z-index: 1;
}
.str-img{
    top: 8;
    left: 0;
    z-index: 0;
}
@media (max-width: 1028px) {
    .header-container{
        flex-direction: column;
        margin-left: -70px;
    }

    }
@media (max-width: 475px) {

    .header-imgs-container{
    max-width: 296px;
    height: 221px;
    margin-top: 45px;
    position: relative;
 
}
.tilt-img{
    top: 9;
    left: -113px;
    z-index: 1;
}
.header-headlines-container h1{
    font-size: 34px;
}
.header-headlines-container p{
    font-size: 18.4px;
    
}
.header-container{
    margin-left: -81px;
}
.header-btn{
    width: 290px;
    font-size: 17px;
    margin-left: 4px;
}
}
@media (min-width: 640px) and (max-width:768px) {
    .header-headlines-container h1{
    font-size: 34px;
}
.header-headlines-container p{
    font-size: 18.4px;
    
}
.header-container{
        flex-direction: column;
        margin-left: -100px;
    }
.header-btn{
    width: 200px;
    margin-left: -1px;
}
.header-imgs-container{
    margin-left: 55px;
    margin-top: 55px;
    position: relative;
}

}
@media (min-width:475px) and (max-width:639px) {
    .header-headlines-container h1{
    font-size: 34px;
}
.header-headlines-container p{
    font-size: 18.4px;
    
}
.header-container{
    margin-left: -81px;
}
.header-imgs-container{
    max-width: 296px;
    height: 330px;
    margin-top: 60px;
    position: relative;
    margin-left: 40px;
}
.tilt-img{
    top: 10px;
    left: -150px;
    z-index: 1;
}
}

  

