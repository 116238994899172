.form-container-wrapper {
  display: flex;
  justify-content: center;
}
.form-container {
  background-color:rgba(229, 229, 229, 1);
  width: 1392px;
  height: 602px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.form-headline-div {
  height: 192px;
  width: 568px;
  margin-left: 80px;
  margin-top: -360px;
  font-family: "glock";
  font-size: 20px;
}
.form-input-section {
  min-height: 507px;
  max-width: 500px;
  background-color: white;
  margin-left: 175px;
  border-radius: 20px;
  z-index: 3;
}
.form-inputs-container {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin-left: 40px;
}
.form-btn-container {
  width: 420px;
  height: 29px;
  margin-top: 35px;
  margin-left: 120px;
  margin-bottom: 20px;
}

.input-field-name {
  height: 30px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 0 0.5px grey;
  padding: 7px;
}
.input-field-name::placeholder {
  font-family: "aktiv";
}
.input-field-email {
  margin-top: 12px;
  height: 30px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 0 0.5px grey;
  padding: 7px;
}
.input-field-email::placeholder {
  font-family: "aktiv";
}
.text-area-message {
  margin-top: 12px;
  height: 120px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 0 0.5px grey;
  padding: 10px;
  resize: none;
  font-family: "aktiv";
}

.form-submit-btn {
  width: 160px;
  height: 42px;
  background-color: black;
  color: white;
  border-radius: 30px;
  margin-left: 170px;
  margin-top: 25px;
  cursor: pointer;
  font-family: "Mariupol";
  font-size: 16px;
  border: none;
}
.spnsr-msm-btn {
  margin-right: 25px;
  margin-left: 90px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: "aktiv-med";
  font-size: 14px;
  font-weight:bold;
}
.help-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: "Mariupol";
}

.btn-line {
  width: 420px;
  height: 2px;
  background-color: black;
  margin-left: 40px;
  margin-top: -20px;
  margin-bottom: 30px;
  
}

/* .spnsr-msm-btn.active:after {
  content: "";
  display: block;
  width: 187%;
  height: 0.4px;
  background-color: black;
  margin-top: 12px;
  margin-left: -78px;
 
} */

.help-btn.active::after {
  content: "";
  display: block;
  width: 188%;
  height: 0.4px;
  background-color: black;
  margin-top: 12px;
  margin-left: -17px;
}
.tape-down {
  position: absolute;
  width: 138.7%;
  z-index: 2;
  transform: rotate(0deg);
  height: auto;
  margin-left: -5px;
}
.tape-up {
  position: absolute;
  width: 138.7%;
  z-index: 1;
  margin-top: -36px;
  transform: rotate(1deg);
  height: auto;
  margin-left: -177px;
}
.tape-container {
  margin-bottom: -150px;
  overflow: hidden;
}

.error-text {
  font-family: "Mariupol";
  color: red;
  margin-left: 130px;
}
.char-remaining {
  margin-top: 290px;
  position: absolute;
  margin-left: 359px;
  font-size: 8px;
  z-index: 2;
  font-family: "Mariupol";
  color: grey;
}
@media (max-width: 1122px) {
  .tape-down,
  .tape-up {
    display: none;
  }

  .form-container {
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
  }
  .form-headline-div {
    width: 101%;
    text-align: center;
    margin-bottom: 430px;
    margin-left: 0px;
    padding-top: 20px;
    font-size: 16px;
  }
  .form-headline {
    margin-top: 500px;
  }
  .form-input-section {
    margin-left: -20px;
  }
  .help-btn.active::after {
    left: 49%;
    bottom: 402px;
  }
  .spnsr-msm-btn.active::after {
    left: 33.5%;
    bottom: 402px;
  }
}
@media (max-width: 884px) {
  .form-headline-div {
    font-size: 13.5px;
  }
}
@media (max-width: 511px) {
  .form-headline-div {
    font-size: 10.5px;
    text-align: left;
    margin-left: 25px;
  }
  .form-container {
    margin-left: 1%;
    margin-right: 1%;
  }
  .form-input-section {
    margin-left: 0px;
    height: 417px;
    width: 290px;
    margin-top: 10px;
  }
  .form-inputs-container {
    margin-left: 0px;
  }
  .form-inputs-container input {
    margin-left: 5%;
    margin-right: 5%;
  }
  .form-inputs-container textarea {
    margin-left: 5%;
    margin-right: 5%;
  }
  .form-btn-container {
    margin-left: 17px;
  }
  .form-submit-btn {
    width: 250px;
    margin-left: 20px;
  }
  .form-btn-container {
    margin-top: 20px;
  }
  .btn-line {
    width: 1320px;
    background-color: grey;
    margin-left: -515px;
    margin-top: -20px;
    margin-bottom: 25px;
    transform: scale(0.21);
  }
  
.char-remaining {
  margin-top: 290px;
  margin-left: 215px;
}
.spnsr-msm-btn.active:after {
  width: 124%;
  margin-left: -6px;
 
}

.help-btn.active::after {
  width: 123%;
  margin-left: -17px;
}
}
@media (min-width: 511px) and (max-width: 1121px) {
  .form-input-section {
    margin-left: 10px;
    margin-top: 10px;
  }
}
@media (min-width:1122px) and (max-width: 1224px){
  .form-headline-div {
    font-size: 16px;
  }
}
@media (min-width: 885px) and (max-width:972px){
  .form-headline-div {
    font-size: 16px;
  }
}
